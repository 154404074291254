import * as React from 'react'
import cn from 'classnames'

export interface IProps {
  kind: 'open' | 'submitted' | 'outdated' | 'checkedout' | 'canceled'
  text?: React.ReactNode
}

const getLabel = (kind: string) => {
  switch (kind) {
    case 'open':
      return 'Open'
    case 'submitted':
      return 'Submitted'
    case 'outdated':
      return 'Outdated'
    case 'checkedout':
      return 'Checked Out'
    case 'canceled':
      return 'Canceled'
  }
}
const Status: React.FunctionComponent<IProps> = ({ kind, text }) => (
  <div
    className={cn('status', `-${kind}`, {
      '-children': text,
    })}
  >
    <div className="status_label">{getLabel(kind)}</div>
    {text && <div className="status_text">{text}</div>}
  </div>
)

export default Status
