import * as React from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from './Breadcrumb'
import Menu from './Menu'
import Avatar from '../../../components/Avatar'

interface IProps {
  breadcrumb?: []
  clientMenu?: boolean
}

const Header: React.FunctionComponent<IProps> = ({ clientMenu, breadcrumb }) => (
  <header className="header">
    <div className="container header_container">
      <Link to="/" className="header_logo">
        <img src={require('./logo.png')} alt="Forshaw Logo" />
      </Link>
      {breadcrumb && <Breadcrumb />}

      <div className="header_separator" />
      <Menu clientMenu={clientMenu} />
      <div className="header_user">
        <Avatar
          user={{
            id: 0,
            name: '',
            avatar: 'http://ithare.com/wp-content/uploads/BB_userpic_0012.png',
            role: 'internal',
            email: '',
            lastSignInAt: '',
          }}
        />
      </div>
    </div>
  </header>
)

export default Header
