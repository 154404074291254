export const ORDER_FILTER_QUERY = `
query OrderFilter($first: Int, $after: String, $last: Int, $before: String,
                  $accountId: Int, $branchId: Int, $scope: OrderScopesEnum, $period: ISO8601Date, $text: String) {
  internal {
    orderFilter(first: $first, after: $after, last: $last, before: $before,
                accountId: $accountId, branchId: $branchId, scope: $scope, period: $period, text: $text) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalItems
      nodes {
        id
        name
        status
        rejectReason
        kind
        navOrderId
        navReferenceId
        dueDateAt
        submittedAt
        submittedBy { name }
        requestedAt
        requestedBy { name }
        approvedBy { name }
        approvedAt
        updatedAt
        updatedBy { name }
        shipmentDateAt
        approveDueDateAt
        branch {
          id
          name
          navId
        }
        account {
          id
          name
        }
        orderItems {
          id
          amountRequested
          product {
            id
            price
          }
        }
        orderTrackingStatuses {
          id
          status
          shippedAt
          trackingNumber
          shippingAgent
          soNumber
          trackingUrl
        }
        cost
        submittedAt
      }
    }
  }
}
`

export const MANAGER_ORDER_FILTER_QUERY = `
query ManagerOrderFilter($first: Int, $after: String, $last: Int, $before: String,
                         $branchId: Int, $scope: OrderScopesEnum, $period: ISO8601Date, $text: String) {
  manager {
    orderFilter(first: $first, after: $after, last: $last, before: $before,
                branchId: $branchId, scope: $scope, period: $period, text: $text) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalItems
      nodes {
        id
        name
        status
        rejectReason
        kind
        navOrderId
        navReferenceId
        dueDateAt
        submittedAt
        submittedBy { name }
        approvedBy { name }
        approvedAt
        requestedAt
        requestedBy { name }
        updatedAt
        updatedBy { name }
        shipmentDateAt
        approveDueDateAt
        branch {
          id
          name
          navId
        }
        account {
          id
          name
        }
        orderItems {
          id
          amountRequested
          product {
            id
            price
          }
        }
        orderTrackingStatuses {
          id
          status
          shippedAt
          trackingNumber
          shippingAgent
          soNumber
          trackingUrl
        }
        cost
        submittedAt
      }
    }
  }
}
`

export const FIND_ORDER_QUERY = `
query FindOrder($orderId: Int!) {
  internal {
    orderFind(id: $orderId) {
      id
      name
      kind
      status
      navOrderId
      navReferenceId
      dueDateAt
      submittedAt
      submittedBy { name }
      requestedAt
      requestedBy { name }
      approvedAt
      approvedBy { name }
      rejectReason
      updatedAt
      updatedBy { name }
      orderItems {
        id
        price
        unit
        amountRequested
        product {
          id
          name
          code
          photo
          salesUnit
          unit
          price
          stockState
          stockInventory
        }
      }
      orderTrackingStatuses {
        id
        status
        shippedAt
        trackingNumber
        shippingAgent
        soNumber
        trackingUrl
      }
      cost
      shipmentDateAt
      approveDueDateAt
      branch {
        id
        name
      }
      account { name }
      navCsv
    }
  }
}
`

export const MANAGER_FIND_ORDER_QUERY = `
query ManagerFindOrder($orderId: Int!) {
  manager {
    orderFind(id: $orderId) {
      id
      name
      kind
      status
      navOrderId
      navReferenceId
      dueDateAt
      submittedAt
      submittedBy { name }
      requestedAt
      requestedBy { name }
      approvedAt
      approvedBy { name }
      rejectReason
      updatedAt
      updatedBy { name }
      orderItems {
        id
        price
        unit
        amountRequested
        product {
          id
          name
          code
          photo
          salesUnit
          unit
          price
          stockState
          stockInventory
        }
      }
      orderTrackingStatuses {
        id
        status
        shippedAt
        trackingNumber
        shippingAgent
        soNumber
        trackingUrl
      }
      cost
      shipmentDateAt
      approveDueDateAt
      branch {
        id
        name
      }
      account { name }
      navCsv
    }
  }
}
`

export const ORDER_REQUEST_TRACKING_INFO = `
mutation RequestTrackingInfo($id: Int!) {
  orderRequestTrackingInfo(id: $id) {
    errors
  }
}`

export const APPROVE_ORDER_QUERY = `
mutation ApproveSubmit($id: Int!) {
  orderApprove(id: $id) {
    errors,
    result {
      id
      status
      approvedBy { name }
      approvedAt
    }
  }
}`

export const REOPEN_ORDER_QUERY = `
mutation ReopenSubmit($id: Int!) {
  orderReopen(id: $id) {
    errors,
    result {
      id
      status
      approvedBy { name }
      approvedAt
    }
  }
}`

export const REQUEST_ORDER_QUERY = `
mutation RequestSubmit($id: Int!) {
  orderRequest(id: $id) {
    errors,
    result {
      id
      status
      approvedBy { name }
      approvedAt
    }
  }
}`

export const REJECT_ORDER_QUERY = `
mutation RejectOrder($id: Int!, $attributes: OrderRejectAttributes!) {
  orderReject(id: $id, attributes: $attributes) {
    errors,
    result {
      id
      status
      rejectReason
      approvedBy { name }
      approvedAt
    }
  }
}`

export const UPDATE_ORDER_ITEM_QUERY = `
mutation OrderItemUpdate($id: Int!, $attributes: OrderItemUpdateAttributes!) {
  orderItemUpdate(id: $id, attributes: $attributes) {
    errors,
    result {
      amountRequested
      updatedAt
      updatedBy { name }
    }
  }
}
`

export const CREATE_ORDER_ITEM_QUERY = `
mutation OrderItemCreate($attributes: OrderItemCreateAttributes!) {
  orderItemCreate(attributes: $attributes) {
    errors,
    result {
      id
      amountRequested
      product {
        id
        name
        code
        photo
        salesUnit
        unit
        price
        stockState
        stockInventory
      }
    }
  }
}
`

export const CREATE_ORDER_QUERY = `
mutation OrderCreate($branchId: Int!) {
  orderCreate(branchId: $branchId) {
    errors,
    result {
      id
      status
      navReferenceId
      updatedAt
      updatedBy { name }
    }
  }
}
`

export const UPDATE_ORDER_QUERY = `
mutation OrderUpdate($id: Int!, $attributes: OrderUpdateAttributes!) {
  orderUpdate(id: $id, attributes: $attributes) {
    errors,
    result {
      id
      status
      navReferenceId
      updatedAt
      updatedBy { name }
    }
  }
}
`

export const SUBMIT_ORDER_QUERY = `
mutation OrderSubmit($id: Int!) {
  orderSubmit(id: $id) {
    errors,
    result {
      id
      year
      month
      status
      kind
      navReferenceId
      shipmentDateAt
      approveDueDateAt
      submittedBy { name }
      submittedAt
      approvedAt
      approvedBy { name }
      updatedAt
      updatedBy { name }
    }
  }
}
`
