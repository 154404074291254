import * as React from 'react'
import Search from '../../../../components/Search'
import Pager from '../../../../components/Pager'

const Filter: React.FunctionComponent = () => (
  <form className="filter">
    <div className="filter_col -fix300">
      <Search placeholder="Search Product" />
    </div>

    <div className="filter_sep -fluid" />
    <div className="filter_col -fluid">
      <Pager onNext={() => {}} onPrevious={() => {}} />
    </div>
  </form>
)

export default Filter
