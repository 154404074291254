import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import Filter from './Filter'
import EditIcon from '../../../icons/edit.svg'
import ClientDetails from './UserDetails'
import Header from '../../views/Header'
import Sync from "../../../components/Sync";

const clients: Array<{
  title: string
  branches: number
  users: number
}> = [
  {
    title: 'Client #1',
    branches: 10,
    users: 10,
  },
  {
    title: 'Client #2',
    branches: 10,
    users: 10,
  },
  {
    title: 'Client #3',
    branches: 10,
    users: 10,
  },
]

const ClientsPage: React.FunctionComponent<RouteComponentProps> = (props) => {
  const [clientDetails, toggleClientDetails] = React.useState(false)
  const goToPage = () => {
    props.history.push('/client')
  }

  const editClient = (e: any) => {
    toggleClientDetails(true)
    e.stopPropagation()
  }

  return (
    <>
      <Header />
      <div className="container">
        <Filter />
        <div className="table">
          <div className="table_head-tr">
            <div className="table_head-tr table_td -g25 table_title">Client</div>
            <div className="table_head-tr table_td -g25 table_title">Branches</div>
            <div className="table_head-tr table_td -g25 table_title">Users</div>
            <div className="table_head-tr table_td -g25 table_title">Sync</div>
            <div className="table_head-tr table_td -fix100 table_title" />
          </div>
          {clients.map((client, idx) => (
            <div onClick={goToPage} className="table_tr -link" key={idx}>
              <div className="table_td -g25">{client.title}</div>
              <div className="table_td -g25">{client.branches}</div>
              <div className="table_td -g25">{client.users}</div>
              <div className="table_td -g25"><Sync status="success"/></div>
              <div className="table_td -fix100 -text-right">
                <button className="clear-button link" onClick={editClient}>
                  <EditIcon className="table_edit-link" />
                </button>
              </div>
            </div>
          ))}
        </div>
        {clientDetails && <ClientDetails onClose={() => toggleClientDetails(false)} />}
      </div>
    </>
  )
}

export default ClientsPage
