import * as React from 'react'
import Filter from './Filter'
import cn from 'classnames'
import Header from '../../views/Header'
import OrderSnippet from '../../../components/OrderSnippet'
import MonthTitle from './MonthTitle'
import ProductInfo from './ProductInfo'
import { productMonthList, monthsList } from './_dataset'
import Tile, { IProps as ITileProps } from './Tile'
import ReplenishmentCut from './ReplenishmentCut'
import ActionButton, {ActionButtonWrapper} from '../../../components/ActionButton'

interface IState {
  isShownProduct?: boolean
}

const ReplenishmentTile: React.FunctionComponent<ITileProps> = (props) => {
  const [isOpen, toggleOpen] = React.useState(false)
  let showTimeout: any
  let hideTimeout: any

  return (
    <ReplenishmentCut
      list={[]}
      onShow={() => {
        clearTimeout(hideTimeout)
        toggleOpen(true)
      }}
      onHide={() => toggleOpen(false)}
      isOpen={isOpen}
    >
      <Tile
        onMouseEnter={() => {
          showTimeout = setTimeout(() => toggleOpen(true), 400)
        }}
        onMouseLeave={() => {
          clearTimeout(showTimeout)
          hideTimeout = setTimeout(() => toggleOpen(false), 30)
        }}
        {...props}
      />
    </ReplenishmentCut>
  )
}

const BranchPage: React.FunctionComponent = () => {
  const [isShownProduct, toggleShownProduct] = React.useState(false)

  return (
    <>
      <Header breadcrumb={[]} clientMenu />
      <div className="container">
        <Filter />
        <div className="replenishment">
          {/* HEADER */}
          <div className="replenishment_row -header">
            <div className="replenishment_col -product">
              <div className="replenishment_title">Replenishment Plan</div>
            </div>

            {monthsList.map((month, idx) => (
              <div className="replenishment_col" key={idx}>
                <MonthTitle {...month} />
              </div>
            ))}
          </div>
          {/* /HEADER */}

          {/* BODY */}
          <div className="replenishment_body wonder-scroll">
            {[
              'delete',
              'add',
              'add',
              'delete',
              'delete',
              'delete',
              'add',
              'add',
              'delete',
              'delete',
              'delete',
              'add',
              'add',
              'delete',
              'delete',
              'delete',
              'add',
              'add',
              'delete',
              'delete',
            ].map((product: 'delete' | 'add', idx) => (
              <div key={idx} className="replenishment_row">
                <div className="replenishment_col -product">
                  <ActionButtonWrapper className="replenishment_product">
                    <OrderSnippet action={product} type="small" />
                    <button
                      className={cn('replenishment_product-link clear-button', {
                        '-add': product === 'add',
                        '-delete': product === 'delete',
                      })}
                      onClick={() => toggleShownProduct(true)}
                    />
                    {product === 'delete' && <ActionButton action="remove" />}

                    {product === 'add' && <ActionButton action="add" />}
                  </ActionButtonWrapper>
                </div>

                {productMonthList.map((month, monthidx) => (
                  <div className="replenishment_col" key={monthidx}>
                    <ReplenishmentTile {...month} key={monthidx} />
                  </div>
                ))}
              </div>
            ))}
          </div>
          {/* /BODY */}

          {/* FOOTER */}
          <div className="replenishment_row -footer">
            <div className="replenishment_col -product">
              <div className="replenishment_costs-title -with-sum">Total</div>
            </div>
            {[
              '18 items',
              '17 items',
              '16 items',
              '17 items',
              '13 items',
              '12 items',
              '1 item',
              '11 items',
              '3 items',
              '7 items',
              '32 items',
              '2 items',
            ].map((cost, idx) => (
              <div className="replenishment_col" key={idx}>
                <div className="replenishment_cost">
                  <div className="replenishment_cost-sum">$1,280</div>
                  {cost}
                </div>
              </div>
            ))}
          </div>
          {/* /FOOTER */}
        </div>
      </div>

      {isShownProduct && <ProductInfo onClose={() => toggleShownProduct(false)} />}
    </>
  )
}

export default BranchPage
