import * as React from 'react'
import cn from 'classnames'
import ClickOutside from 'react-click-outside'
import Gear from '../../../../icons/gear.svg'
import BasePopover from '../../../../components/BasePopover'
import { Link } from 'react-router-dom'

interface IProps {
  clientMenu?: boolean
}

const Menu: React.FunctionComponent<IProps> = ({ clientMenu }) => {
  const [isOpen, toggleOpen] = React.useState(false)

  return (
    <nav className="menu">
      {clientMenu ? (
        <ClickOutside onClickOutside={() => toggleOpen(false)}>
          <BasePopover
            className="menu_popup -settings"
            offset={20}
            isOpen={isOpen}
            body={
              <>
                <Link className="menu_popup-link" to="/client_users">
                  Client Users
                </Link>
                <Link className="menu_popup-link" to="/divisions">
                  Division
                </Link>
                <Link className="menu_popup-link" to="/catalog">
                  Repacking catalog
                </Link>
              </>
            }
          >
            <button onClick={() => toggleOpen(!isOpen)} type="button" className="clear-button link menu_toggle">
              <Gear />
              Client Settings
            </button>
          </BasePopover>
        </ClickOutside>
      ) : (
        <>
          <Link
            className={cn('menu_root-link link', {
              '-active': false,
            })}
            to="/clients"
          >
            Clients
          </Link>
          <Link
            className={cn('menu_root-link link', {
              '-active': false,
            })}
            to="/orders"
          >
            Orders
          </Link>
          <Link
            className={cn('menu_root-link link', {
              '-active': false,
            })}
            to="/users"
          >
            Internal Users
          </Link>
        </>
      )}
    </nav>
  )
}

export default Menu
