import * as React from 'react'
import cn from 'classnames'
import SyncIcon from '../../icons/sync_circle.svg'

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  syncing?: boolean
  status?: 'success' | 'failed'
}

const Sync: React.FunctionComponent<IProps> = ({ disabled, syncing, status, ...props }) => (
  <div className="sync">
    <button
      className={cn('sync_button clear-button', {
        '-syncing': syncing,
      })}
      type="button"
      disabled={disabled || syncing}
      {...props}
    >
      <SyncIcon className="sync_icon" />
      {syncing ? <>Syncing...</> : <>Sync</>}
    </button>

    {!syncing && (
      <div
        className={cn('sync_message', {
          '-success': status === 'success',
          '-failed': status === 'failed',
        })}
      >
        Synced 3 hours ago
      </div>
    )}
  </div>
)

export default Sync
