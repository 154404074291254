import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import Filter from './Filter'
import EditIcon from '../../../icons/edit.svg'
import Avatar from '../../../components/Avatar'
import Header from '../../views/Header'
import Splash from '../../../components/Splash'

const clients: Array<{
  title: string
  email: string
  role: string
  branch: string
  login: string
}> = [
  {
    title: 'Joe Jonson',
    email: 'joe.j@forshaw.com',
    role: 'Manager',
    branch: 'Branch #1',
    login: '3 days ago',
  },
  {
    title: 'Bill Willow',
    email: 'joe.j@forshaw.com',
    role: 'Technician',
    branch: 'Branch #1',
    login: '30 min ago',
  },
]

const UsersPage: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <>
      <Header />
      <div className="container">
        <Filter />

        <Splash withWrapper isLoading={false} bgColor="#F2F6FA">
          <div className="table">
            <div className="table_head-tr">
              <div className="table_td -avatar" />
              <div className="table_td -g20 table_title">Users</div>
              <div className="table_td -g20 table_title">Email</div>
              <div className="table_td -g20 table_title">Role</div>
              <div className="table_td -g20 table_title">Branch</div>
              <div className="table_td -g20 table_title">Last log in</div>
            </div>
            {clients.map((client, idx) => (
              <div className="table_tr" key={idx}>
                <div className="table_td -avatar">
                  <Avatar
                    user={{
                      id: 0,
                      name: '',
                      avatar:
                        'http://ithare.com/wp-content/uploads/BB_userpic_0012.png',
                      role: 'internal',
                      email: '',
                      lastSignInAt: '',
                    }}
                  />
                </div>
                <div className="table_td -g20">{client.title}</div>
                <div className="table_td -g20">{client.email}</div>
                <div className="table_td -g20">{client.role}</div>
                <div className="table_td -g20">{client.branch}</div>
                <div className="table_td -g20">{client.login}</div>
              </div>
            ))}
          </div>
        </Splash>
      </div>
    </>
  )
}

export default UsersPage
