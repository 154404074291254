import * as React from 'react'
import Search from '../../../../components/Search'
import Button from '../../../../components/Button'
import NewDivision from "../../../views/NewDivision";

const Filter: React.FunctionComponent = () => {
  const [newDivision, toggleNewDivision] = React.useState(false)

  return (
    <form className="filter">
      <div className="filter_col -large">
        <Search placeholder="Search Division" />
      </div>
      <div className="filter_sep" />
      <div className="filter_col">
        <Button block onClick={() => toggleNewDivision(true)}>
          Create Division
        </Button>
      </div>

      {newDivision && <NewDivision onClose={() => toggleNewDivision(false)} />}
    </form>
  )
}

export default Filter
