import * as React from 'react'
import Filter from './Filter'
import Status, { IProps as IStatusProps } from '../../../components/Status'
import OrderIcon, { IProps as IOrderIconProps } from '../../../components/OrderIcon'
import OrderDetails from './OrderDetails'
import Header from "../../views/Header";

const items: Array<
  {
    title: string
    status: IStatusProps
  } & IOrderIconProps
> = [
  {
    title: 'Replenishment, June',
    orderType: 'replenishment',
    status: {
      kind: 'outdated',
    },
  },
  {
    title: 'Fill In #3',
    orderType: 'fill_in',
    status: {
      kind: 'open',
      text: 'till 06/08',
    },
  },
  {
    title: 'Fill In #2 ',
    orderType: 'fill_in',
    status: {
      kind: 'submitted',
    },
  },
]

const OrdersPage: React.FunctionComponent = () => {
  const [orderDetails, toggleOrderDetails] = React.useState(false)

  return (
    <>
      <Header />
      <div className="container">
        <Filter />
        <div className="table">
          <div className="table_head-tr">
            <div className="table_td -fix50 table_title" />
            <div className="table_td -fix100 table_title">Submitted</div>
            <div className="table_td -w15 table_title">Order</div>
            <div className="table_td -w12 table_title">Status</div>
            <div className="table_td -fix75 table_title">Items</div>
            <div className="table_td -auto table_title">ERP Order</div>
            <div className="table_td -w15 table_title">Client</div>
            <div className="table_td -w15 table_title">Branch</div>
            <div className="table_td -w15 table_title">Submitted by</div>
          </div>
          {items.map((order, idx) => (
            <div onClick={() => toggleOrderDetails(true)} className="table_tr -link" key={idx}>
              <div className="table_td -fix50">
                <OrderIcon orderType={order.orderType} />
              </div>
              <div className="table_td -fix100">&mdash;</div>
              <div className="table_td -w15">{order.title}</div>
              <div className="table_td -w12">
                <Status {...order.status} />
              </div>
              <div className="table_td -fix75">26</div>
              <div className="table_td -auto">CL-0519-2</div>
              <div className="table_td -w15">Clean House Corp</div>
              <div className="table_td -w15">Main Warehouse</div>
              <div className="table_td -w15">Norman Johnson</div>
            </div>
          ))}
        </div>

        {orderDetails && <OrderDetails onClose={() => toggleOrderDetails(false)} />}
      </div>
    </>
  )
}

export default OrdersPage
