import * as React from 'react'
import { Link } from 'react-router-dom'
import ClickOutside from 'react-click-outside'
import HellipIcon from '../../../icons/hellip.svg'
import CrossIcon from '../../../icons/cross-bold.svg'
import LinkIcon from '../../../icons/link.svg'
import BasePopover from '../../../components/BasePopover'
import OrderIcon from '../../../components/OrderIcon'

interface IProps {
  getAppendTarget?(): Element | null
}

const TileCut: React.FunctionComponent<IProps> = ({ getAppendTarget }) => {
  const [isShown, toggleShow] = React.useState(false)
  const [target, setTarget] = React.useState<Element>(document.body)

  React.useEffect(() => {
    if (getAppendTarget) {
      setTarget(getAppendTarget() || document.body)
    }
  })

  return (
    <BasePopover
      appendTarget={target}
      className="tcut_popover"
      isOpen={isShown}
      body={
        <div className="tcut_body">
          <div className="tcut_col">
            <div className="tcut_title">
              2019 <div className="tcut_circle -blue" />
            </div>

            <div className="tcut_scroll wonder-scroll">
              {[0, 1, 2, 3].map((item) => (
                <Link to="/" className="tcut_item" key={item}>
                  <div className="tcut_item-icon -status">
                    <OrderIcon orderType="fill_in" />
                  </div>

                  <div className="tcut_item-title">Fill In #1</div>
                  <div className="tcut_item-units">+10 units</div>
                  <div className="tcut_item-date">April 15</div>
                  <LinkIcon className="tcut_item-icon -link" />
                </Link>
              ))}
            </div>
          </div>

          <div className="tcut_col">
            <div className="tcut_title">
              2019 <div className="tcut_circle -pink" />
            </div>
            <div className="tcut_scroll wonder-scroll">
              <Link to="/" className="tcut_item">
                <div className="tcut_item-icon -status">
                  <OrderIcon orderType="replenishment" />
                </div>

                <div className="tcut_item-title">Replenishment</div>
                <div className="tcut_item-units">+10 units</div>
                <div className="tcut_item-date">April 15</div>
                <LinkIcon className="tcut_item-icon -link" />
              </Link>
            </div>
          </div>
        </div>
      }
    >
      <ClickOutside className="tcut" onClickOutside={() => toggleShow(false)}>
        <>
          {isShown ? (
            <button className="tcut_toggle -active clear-button" onClick={() => toggleShow(false)}>
              <CrossIcon className="tcut_icon -cross" />
            </button>
          ) : (
            <button className="tcut_toggle clear-button" onClick={() => toggleShow(true)}>
              <HellipIcon className="tcut_icon -hellip" />
            </button>
          )}
        </>
      </ClickOutside>
    </BasePopover>
  )
}

export default TileCut
