import * as React from 'react'
import cn from 'classnames'
import ClickOutside from 'react-click-outside'
import ArrowUpIcon from '../../../icons/arrow_up.svg'
import ArrowDownIcon from '../../../icons/arrow_down.svg'
import OrderIcon, { Types as OrderIconTypes } from '../../../components/OrderIcon'

export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'product'
  done?: boolean
  count: string
  statuses: {
    correlation?: 'up' | 'down'
    orderType?: OrderIconTypes
    protected?: boolean
  }
}

const Tile: React.FunctionComponent<IProps> = ({ statuses, done, count, children, type, ...props }) => {
  const [isCount, toggleCount] = React.useState(false)

  return (
    <div
      {...props}
      className={cn('tile', `-${type}`, {
        '-protected': statuses.protected,
        '-done': done,
        '-available': !done,
      })}
    >
      <div className="tile_statuses">
        {statuses.orderType && <OrderIcon orderType={statuses.orderType} />}
        {statuses.correlation && statuses.correlation === 'up' && <ArrowUpIcon className="tile_correlation -up" />}
        {statuses.correlation && statuses.correlation === 'down' && (
          <ArrowDownIcon className="tile_correlation -down" />
        )}
      </div>

      {done ? (
        <>{count}</>
      ) : (
        <button onClick={() => toggleCount(true)} className="tile_count-toggle clear-button">
          {count}
        </button>
      )}

      {!done && isCount && (
        <ClickOutside onClickOutside={() => toggleCount(false)} className="tile_count">
          <input type="number" defaultValue={count} className="tile_count-input" autoFocus />

          <div className="tile_count-controls">
            <button className="tile_count-control clear-button" type="button">
              2
            </button>
            <button className="tile_count-control clear-button" type="button">
              10
            </button>
          </div>
        </ClickOutside>
      )}

      {children}
    </div>
  )
}

export default Tile
