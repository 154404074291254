import * as React from 'react'
import Search from '../../../../components/Search'
import Dropdown from '../../../../components/Dropdown'
import Button from '../../../../components/Button'

const Filter: React.FunctionComponent = () => (
  <form className="filter">
    <div className="filter_col -large">
      <Search />
    </div>
    <div className="filter_sep" />
    <div className="filter_col">
      <Dropdown
        onChange={() => console.log('onChange')}
        value={null}
        itemToString={(value) => (value ? value.label : 'All branches')}
        options={[
          {
            value: 'Catalog',
            label: 'Catalog',
          },
          {
            value: 'Division',
            label: 'Division',
          },
          {
            value: 'Client Users',
            label: 'Client Users',
          },
        ]}
      />
    </div>
    <div className="filter_sep" />
    <div className="filter_col">
      <Dropdown
        onChange={() => console.log('onChange')}
        value={null}
        itemToString={(value) => (value ? value.label : 'All branches')}
        options={[
          {
            value: 'Catalog',
            label: 'Catalog',
          },
          {
            value: 'Division',
            label: 'Division',
          },
          {
            value: 'Client Users',
            label: 'Client Users',
          },
        ]}
      />
    </div>
    <div className="filter_sep" />
    <div className="filter_col">
      <Dropdown
        onChange={() => console.log('onChange')}
        value={null}
        itemToString={(value) => (value ? value.label : 'All branches')}
        options={[
          {
            value: 'Catalog',
            label: 'Catalog',
          },
          {
            value: 'Division',
            label: 'Division',
          },
          {
            value: 'Client Users',
            label: 'Client Users',
          },
        ]}
      />
    </div>
    <div className="filter_sep" />
  </form>
)

export default Filter
