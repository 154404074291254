import { Types as OrderIconTypes } from '../../../components/OrderIcon'

export const productMonthList: Array<{
  done?: boolean
  count: string
  statuses: {
    correlation?: 'up' | 'down'
    orderType?: OrderIconTypes
    protected?: boolean
  }
}> = [
  {
    count: '10',
    statuses: {
      correlation: 'down',
    },
    done: true,
  },
  {
    count: '10',
    statuses: {
      orderType: 'fill_in',
    },
    done: true,
  },
  {
    count: '10',
    statuses: {
      correlation: 'up',
      orderType: 'replenishment',
    },
    done: true,
  },
  {
    count: '10',
    statuses: {},
    done: true,
  },
  {
    count: '10',
    statuses: {
      correlation: 'up',
      orderType: 'fill_in',
    },
  },
  {
    count: '10',
    statuses: {},
  },
  {
    count: '10',
    statuses: {
      protected: true,
    },
  },
  {
    count: '10',
    statuses: {
      correlation: 'down',
      orderType: 'fill_in',
    },
  },
  {
    count: '10',
    statuses: {},
  },
  {
    count: '10',
    statuses: {},
  },
  {
    count: '10',
    statuses: {},
  },
  {
    count: '10',
    statuses: {},
  },
]

export const monthsList: Array<{
  current?: string
  label: string
  done?: boolean
}> = [
  {
    label: 'Jan',
    done: true,
  },
  {
    label: 'Feb',
    done: true,
  },
  {
    label: 'Mar',
    done: true,
  },
  {
    label: 'Apr',
    done: true,
  },
  {
    label: 'May',
    current: '75%',
  },
  {
    label: 'June',
  },
  {
    label: 'July',
  },
  {
    label: 'Aug',
  },
  {
    label: 'Sept',
  },
  {
    label: 'Oct',
  },
  {
    label: 'Nov',
  },
  {
    label: 'Dec',
  },
]
