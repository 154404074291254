import * as React from 'react'
import Modal from '../../../../components/Modal'
import OrderIcon from '../../../../components/OrderIcon'
import OrderSnippet from '../../../../components/OrderSnippet'

interface IProps {
  onClose(): void
}

const OrderDetails: React.FunctionComponent<IProps> = ({ onClose }) => {
  const [toggle, setToggle] = React.useState({
    label: 'Manager',
    value: 0,
  })

  return (
    <Modal onClose={onClose} title="Order Details">
      <div className="order-details">
        <div className="order-details_col -left">
          <div className="order-details_title">
            <div className="order-details_title-icon">
              <OrderIcon orderType="replenishment" />
            </div>
            Fill In #2
          </div>
          {[
            {
              label: 'Date',
              value: '05/25/2019',
            },
            {
              label: 'ORDER',
              value: 'CL-0519-2',
            },
            {
              label: 'CLIENT',
              value: 'ABC Inc.',
            },
            {
              label: 'BRANCH',
              value: 'Main Warehouse',
            },
            {
              label: 'SUBMITTED BY',
              value: 'Katie Street',
            },
          ].map((prop, idx) => (
            <dl className="order-details_props" key={idx}>
              <dt>{prop.label}</dt>
              <dd>{prop.value}</dd>
            </dl>
          ))}
        </div>
        <div className="order-details_col -right">
          {[
            {
              snippet: {
                repack: '3 lbs',
              },
            },
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
          ].map((item, idx) => (
            <OrderSnippet onDelete={() => console.log('Delete Event')} key={idx} {...item} />
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default OrderDetails
