import * as React from 'react'

const UploadAvatar: React.FunctionComponent = () => {
  const [image, setImage] = React.useState()

  return (
    <div className="upload-avatar">
      <label className="upload-avatar_circle">
        <input
          type="file"
          className="upload-avatar_input"
          onChange={() =>
            setImage(
              'https://cdn3.vectorstock.com/i/thumb-large/46/72/stylish-young-man-in-glasses-avatar-or-userpic-vector-12824672.jpg'
            )
          }
        />
        {image && <img className="upload-avatar_image" src={image} alt="" />}
      </label>
    </div>
  )
}

export default UploadAvatar
