import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import Filter from './Filter'
import EditIcon from '../../../icons/edit.svg'
import Status, { IProps as IStatusProps } from '../../../components/Status'
import Header from "../../views/Header";

const branches: Array<{
  title: string
  address: string
  status: IStatusProps
}> = [
  {
    title: 'Branch #1',
    address: '456 N Andrews St Merna, NE',
    status: {
      kind: 'submitted',
    },
  },
  {
    title: 'Branch #2',
    address: '456 N Andrews St Merna, NE',
    status: {
      kind: 'submitted',
      text: 'May, 29'
    },
  },
  {
    title: 'Branch #3',
    address: '456 N Andrews St Merna, NE',
    status: {
      kind: 'submitted',
    },
  },
]

const ClientPage: React.FunctionComponent<RouteComponentProps> = (props) => {
  const goToPage = () => {
    props.history.push('/branch')
  }

  const editBranch = (e: any) => {
    console.log('11')
    e.stopPropagation()
  }

  return (<>
      <Header clientMenu breadcrumb={[]}/>
    <div className="container">
      <Filter />
      <div className="table">
        <div className="table_head-tr">
          <div className="table_td -g50 table_title">Branch</div>
          <div className="table_td -g50 table_title">This month replenishment</div>
          <div className="table_td -fix100" />
        </div>
        {branches.map((branch, idx) => (
          <div onClick={goToPage} className="table_tr -link" key={idx}>
            <div className="table_td -g50">
              <b>{branch.title}</b>
              <br/>
              <small>{branch.address}</small>
            </div>
            <div className="table_td -g50">
              <Status {...branch.status} />
            </div>
            <div className="table_td -fix100 -text-right">
              <button className="clear-button link" onClick={editBranch}>
                <EditIcon className="table_edit-link" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
  )
}

export default ClientPage
