export const ORDER_ITEM_ATTS_FRAGMENT = `
fragment orderItemAttrs on OrderItem {
  id
  updatedAt
  updatedBy { name }
  amountRequested
  forecast {
    amountSuggested
    amountMinSuggested
    amountMaxSuggested
  }
  product {
    id
    name
    code
    photo
    salesUnit
  }
}
`
export const ORDER_ATTRS_FRAGMENT = `
${ORDER_ITEM_ATTS_FRAGMENT}
fragment orderAttrs on Order {
  id
  year
  month
  status
  kind
  dueDateAt
  approveDueDateAt
  shipmentDateAt
  updatedAt
  updatedBy { name }
  submittedBy { name }
  submittedAt
  approvedAt
  approvedBy { name }
  autoSubmitted
  rejectReason
  orderItems {
    ...orderItemAttrs
  }
}
`

export const PLAN_REPLENISH_QUERY = `
${ORDER_ATTRS_FRAGMENT}
mutation PropagateYearPlan($branchId: Int!, $year: Int!) {
  propagateYearPlan(branchId: $branchId, year: $year) {
    replenish: result {
      ...orderAttrs
    }
    errors
  }
}
`
export const PLAN_FILLIN_QUERY = `
${ORDER_ATTRS_FRAGMENT}
query PlanFillin($accountId: Int!, $branchId: Int!, $year: Int!) {
  internal {
    accounts(id: $accountId) {
      branches(id: $branchId) {
        orders(kind: fill_in, year: $year, status: submitted) {
          ...orderAttrs
        }
      }
    }
  }
}
`

export const ANNUAL_PLAN_QUERY = `
${ORDER_ATTRS_FRAGMENT}
query AnnualPlan($branchId: Int!, $year: Int!, $productId: Int) {
  internal {
    retrieveAnnualPlan(branchId: $branchId, year: $year, productId: $productId) {
      ...orderAttrs
    }
  }
}
`

export const MANAGER_ANNUAL_PLAN_QUERY = `
${ORDER_ATTRS_FRAGMENT}
query ManagerAnnualPlan($branchId: Int!, $year: Int!, $productId: Int) {
  manager {
    retrieveAnnualPlan(branchId: $branchId, year: $year, productId: $productId) {
      ...orderAttrs
    }
  }
}
`

export const ANNUAL_PLAN_BRANCH_QUERY = `
query AnnualPlanBranch(
  $accountId: Int!,
  $branchId: Int!,
  $year: Int!,
  $fromDate: ISO8601DateTime,
  $toDate: ISO8601DateTime
) {
  internal {
    accounts(id: $accountId) {
      nodes {
        branches(id: $branchId) {
          id
          name
          plannedYears
          products: plannedProducts {
            id
            name
            price
            code
            photo
            salesUnit
          }
          forecasts(year: $year) {
            id
            year
            month
            productId
            amountSuggested
            amountMinSuggested
            amountMaxSuggested
          }
          shipments(fromDate: $fromDate, toDate: $toDate) {
            id
            date
            productId
            amount
          }
        }
      }
    }
  }
}
`

export const MANAGER_ANNUAL_PLAN_BRANCH_QUERY = `
query ManagerAnnualPlanBranch($branchId: Int!, $year: Int!, $fromDate: ISO8601DateTime, $toDate: ISO8601DateTime) {
  manager {
    account {
      branches(id: $branchId) {
        id
        name
        plannedYears
        products: plannedProducts {
          id
          name
          price
          code
          photo
          salesUnit
        }
        forecasts(year: $year) {
          id
          year
          month
          productId
          amountSuggested
          amountMinSuggested
          amountMaxSuggested
        }
        shipments(fromDate: $fromDate, toDate: $toDate) {
          id
          date
          productId
          amount
        }
      }
    }
  }
}
`

export const UPDATE_ORDER_ITEM_QUERY = `
mutation OrderItemUpdate($id: Int!, $attributes: OrderItemUpdateAttributes!) {
  orderItemUpdate(id: $id, attributes: $attributes) {
    errors,
    result {
      amountRequested
      updatedAt
      updatedBy { name }
    }
  }
}
`

export const CREATE_ORDER_ITEM_QUERY = `
mutation OrderItemCreate($attributes: OrderItemCreateAttributes!) {
  orderItemCreate(attributes: $attributes) {
    errors,
    result {
      id
      updatedAt
      updatedBy { name }
      amountRequested
      forecast {
        amountSuggested
        amountMinSuggested
        amountMaxSuggested
      }
      product {
        id
        name
        code
        salesUnit
        photo
      }
    }
  }
}
`

export const SUBMIT_ORDER_QUERY = `
mutation OrderSubmit($id: Int!) {
  orderSubmit(id: $id) {
    errors,
    result {
      id
      status
      submittedBy { name }
      submittedAt
      approvedAt
      approvedBy { name }
      updatedAt
      updatedBy { name }
    }
  }
}
`

export const APPROVE_ORDER_QUERY = `
mutation ApproveSubmit($id: Int!) {
  orderApprove(id: $id) {
    errors,
    result {
      id
      status
      approvedBy { name }
      approvedAt
    }
  }
}
`

export const UPDATE_ORDER_QUERY = `
mutation OrderUpdate($id: Int!, $attributes: OrderUpdateAttributes!) {
  orderUpdate(id: $id, attributes: $attributes) {
    errors,
    result {
      id
      status
      updatedAt
      updatedBy { name }
    }
  }
}
`

export const ADD_PLAN_PRODUCT = `
${ORDER_ITEM_ATTS_FRAGMENT}
mutation AddPlanProduct($branchId: Int!, $productId: Int!) {
  addPlanProduct(branchId: $branchId, productId: $productId) {
    errors
    result {
      ...orderItemAttrs
      orderId
    }
  }
}`

export const POPULATE_PLAN = `
mutation PopulatePlan($branchId: Int!) {
  populatePlan(branchId: $branchId) {
    errors
    success
  }
}`

export const REMOVE_PLAN_PRODUCT = `
mutation RemovePlanProduct($branchId: Int!, $productId: Int!) {
  removePlanProduct(branchId: $branchId, productId: $productId) {
    errors
    result {
      id
      orderId
    }
  }
}`
