import * as React from 'react'
import ModalWrapper from '../../../components/Modal/ModalWrapper'
import Button from '../../../components/Button'
import LoadingIcon from '../../../icons/loading.svg'
import OkIcon from '../../../icons/ok_cirlce.svg'
import WrongIcon from '../../../icons/cross_circle.svg'

interface IProps {
  onClose(): void
}
const ImportClients: React.FunctionComponent<IProps> = ({ onClose }) => {
  const [status, setStatus] = React.useState('ready')

  React.useEffect(
    () => {
      if (status === 'progress') {
        setTimeout(() => setStatus('done'), 1000)
      }
    },
    [status]
  )

  return (
    <ModalWrapper className="import">
      <button className="import_backdrop clear-button" onClick={onClose} />

      <div className="import_dialog">
        {status !== 'progress' && <button className="import_close clear-button link" onClick={onClose} />}

        {/* DEFAULT STATE */}
        {status === 'ready' && (
          <>
            <div className="import_title">Import clients</div>
            <label className="import_dropzone">
              <div className="import_dropzone-text">
                Drag and drop the file here <br />
                or click to select on computer
              </div>
              <input type="file" className="import_dropzone-input" />
            </label>
            <Button block onClick={() => setStatus('progress')}>
              START IMPORT
            </Button>
            <a href="/" className="import_download primary-link">
              Download import template
            </a>
          </>
        )}

        {/* WHEN LIST IS LOADING */}
        {status === 'progress' && (
          <>
            <div className="import-icon">
              <LoadingIcon width={70} height={70} />
            </div>
            <div className="import_info-title">Importing</div>
            <div className="import_info-text">Just a moment</div>
          </>
        )}

        {/* WHEN LIST IS UPLOADED */}
        {status === 'done' && (
          <>
            <div className="import-icon -ok">
              <OkIcon width={50} height={50} />
            </div>
            <div className="import_info-title">236 clients has been imported</div>
            <div className="import_info-text">Close the window and check results</div>
            <Button block color="primary-link" onClick={onClose} className="import_info-button">
              Done
            </Button>
          </>
        )}

        {/* WHEN LIST IS UPLOADED WITH ERRORS */}
        {status === 'error' && (
          <>
            <div className="import-icon -wrong">
              <WrongIcon width={50} height={50} />
            </div>
            <div className="import_info-title">Something went wrong</div>
            <div className="import_info-text">Check you and try again</div>
            <Button block color="primary-link" onClick={() => setStatus('ready')} className="import_info-button">
              Try Again
            </Button>
          </>
        )}
      </div>
    </ModalWrapper>
  )
}

export default ImportClients
