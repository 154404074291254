import * as React from 'react'
import cn from 'classnames'
import ModalWrapper from '../../../components/Modal/ModalWrapper'
import CrossIcon from '../../../icons/cross.svg'
import ResetIcon from '../../../icons/reset.svg'
import OrderSnippet from '../../../components/OrderSnippet'
import Pager from '../../../components/Pager'
import Toggle from '../../../components/Toggle'
import RangeControl from '../../../components/RangeControl'
import { monthsList, productMonthList } from './_dataset'
import Tile from './Tile'
import TileCut from './TileCut'

interface IProps {
  onClose(): void
}

const ProductInfo: React.FunctionComponent<IProps> = ({ onClose }) => {
  const [range, setRange] = React.useState(0)
  const [compare, toggleCompare] = React.useState()

  return (
    <ModalWrapper className="product">
      <div className="product_header">
        <div className="product_container container">
          <div className="product_header-title">Product Info</div>
          <button onClick={onClose} className="product_close clear-button">
            <CrossIcon />
          </button>
        </div>
      </div>
      <div className="product_header">
        <div className="product_container container">
          <div className="product_header-row">
            <div className="product_header-col -big">
              <OrderSnippet type="hero" />
            </div>
            <div className="product_header-col -v-sep" />
            <div className="product_header-col -small product_toggle-compare">
              <Toggle
                value={compare}
                onChange={toggleCompare}
                option={{
                  value: '1',
                  label: 'Compare to 2018',
                }}
              />
              <div className="product_header-col -h-sep" />
              <Pager onNext={() => {}} onPrevious={() => {}} />
            </div>
            <div className="product_header-col -v-sep" />
            <div className="product_header-col -big">
              <div className="product_range">
                <div className="product_range-title">Change product trend</div>
                <RangeControl
                  step={20}
                  suffix="%"
                  min={-100}
                  value={range}
                  onChange={value => {
                    console.log(value)
                    setRange(value)
                  }}
                />

                <button className="product_range-reset clear-button">
                  <ResetIcon /> Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="graph">
          {monthsList.map((month, idx) => (
            <div className="graph_month" key={idx} />
          ))}
          <div className="graph_frame">
            <img src="https://monosnap.com/image/p3ttr3iobsIhOSaJSFdsn9Nw5qUWsg" />
          </div>
        </div>
        <div className="product_row">
          {monthsList.map((month, idx) => (
            <div className="product_col" key={idx}>
              <div
                className={cn('product_month-title', {
                  '-current': month.current,
                })}
              >
                {month.label}
              </div>
            </div>
          ))}
        </div>
        <div className="product_row">
          {productMonthList.map((month, monthidx) => (
            <div className="product_col" key={monthidx}>
              <Tile type={'product'} {...month}>
                {month.done && (
                  <TileCut
                    getAppendTarget={() => document.querySelector('.product')}
                  />
                )}
              </Tile>
            </div>
          ))}
        </div>
      </div>
    </ModalWrapper>
  )
}

export default ProductInfo
