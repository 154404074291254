export const ORDER_FILTER = `
query OrderFilter($first: Int, $after: String, $last: Int, $before: String,
                  $accountId: Int, $branchId: Int, $scope: OrderScopesEnum) {
  internal {
    orderFilter(first: $first, after: $after, last: $last, before: $before,
                accountId: $accountId, branchId: $branchId, scope: $scope) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        name
        status
        kind
        navOrderId
        dueDateAt
        submittedBy { name }
        updatedAt
        updatedBy { name }
        approvedAt
        approvedBy { name }
        branch {
          id
          name
        }
        account {
          id
          name
        }
        submittedAt
      }
    }
  }
}
`

export const FIND_ORDER_QUERY = `
query FindOrder($orderId: Int!) {
  internal {
    orderFind(id: $orderId) {
      id
      name
      kind
      status
      navOrderId
      dueDateAt
      submittedAt
      submittedBy { name }
      approvedAt
      approvedBy { name }
      updatedAt
      updatedBy { name }
      orderItems {
        amountRequested
        product {
          id
          name
          code
          photo
          salesUnit
        }
      }
      orderTrackingStatuses {
        id
        status
        shippedAt
        trackingNumber
        shippingAgent
        soNumber
        trackingUrl
      }
      branch {
        id
        name
      }
      account {
        id
        name
      }
      navCsv
    }
  }
}
`
