import * as React from 'react'
import cn from 'classnames'
import Modal from '../../../components/Modal'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import Search from '../../../components/Search'
import OrderSnippet from '../../../components/OrderSnippet'
import ActionButton, {
  ActionButtonWrapper,
} from '../../../components/ActionButton'
import Avatar from '../../../components/Avatar'

interface IProps {
  onClose(): void
}

const clients: Array<{
  title: string
  email: string
  role: string
  branch: string
  login: string
}> = [
  {
    title: 'Joe Jonson',
    email: 'joe.j@forshaw.com',
    role: 'Manager',
    branch: 'Branch #1',
    login: '3 days ago',
  },
  {
    title: 'Bill Willow',
    email: 'joe.j@forshaw.com',
    role: 'Technician',
    branch: 'Branch #1',
    login: '30 min ago',
  },
]

const NewDivision: React.FunctionComponent<IProps> = ({ onClose }) => {
  const [tab, toggleTab] = React.useState('products')

  return (
    <Modal onClose={onClose} title="Division Info">
      <form className="form">
        <div className="form_group">
          <Input label="Name" placeholder="User name" />
        </div>

        <div className="tabs">
          <button
            type="button"
            onClick={() => toggleTab('products')}
            className={cn('tabs_button', 'clear-button', {
              '-active': tab === 'products',
            })}
          >
            Products
          </button>
          <button
            type="button"
            onClick={() => toggleTab('technicians')}
            className={cn('tabs_button', 'clear-button', {
              '-active': tab === 'technicians',
            })}
          >
            Technicians
          </button>
        </div>
        <div className="form_group">
          <Search />
        </div>
        {tab === 'products' &&
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, idx) => (
            <ActionButtonWrapper>
              <OrderSnippet key={idx} {...item} />
              <ActionButton action="remove" />
            </ActionButtonWrapper>
          ))}
        {tab === 'technicians' && (
          <div className="table">
            {clients.map((client, idx) => (
              <ActionButtonWrapper className="table_tr" key={idx}>
                <div className="table_td -avatar">
                  <Avatar
                    user={{
                      id: 0,
                      name: '',
                      avatar:
                        'http://ithare.com/wp-content/uploads/BB_userpic_0012.png',
                      role: 'internal',
                      email: '',
                      lastSignInAt: '',
                    }}
                  />
                </div>
                <div className="table_td -fix150">{client.title}</div>
                <div className="table_td -no-scale">{client.email}</div>
                <ActionButton action="add" />
              </ActionButtonWrapper>
            ))}
          </div>
        )}

        <div className="form_controls">
          <div className="form_controls-container">
            <Button color="red-link" className="form_control -left">
              Delete user
            </Button>
            <Button color="default-link" className="form_control -size">
              Cancel
            </Button>
            <Button color="success" className="form_control -size">
              Save
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default NewDivision
