import * as React from 'react'
import ReplenishmentCut from './ReplenishmentCut'
import LockIcon from '../../../icons/lock.svg'
import LockOkIcon from '../../../icons/lock_ok.svg'
import ChevronDown from '../../../icons/chevron_down_tiny.svg'
import CrossIcon from '../../../icons/cross_cirlce_tiny.svg'
import BasePopover from '../../../components/BasePopover'

const MonthTitle: React.FunctionComponent<{
  current?: string
  label: string
  done?: boolean
}> = ({ current, done, label }) => {
  const [isOpen, toggleOpen] = React.useState(false)

  if (current) {
    return (
      <ReplenishmentCut
        onShow={() => toggleOpen(true)}
        onHide={() => toggleOpen(false)}
        isOpen={isOpen}
        text={`Submit by ${label} 14`}
      >
        <div
          onMouseEnter={() => toggleOpen(true)}
          onMouseLeave={() => toggleOpen(false)}
          className="replenishment_month -current"
        >
          <BasePopover
            isOpen={true}
            offset={0}
            className="menu_popup -replenishment"
            body={
              <>
                <button className="menu_popup-link clear-button">Set to Open</button>
                <button className="menu_popup-link clear-button">Set to Approved</button>
                <button className="menu_popup-link clear-button">Set to Submitted</button>
              </>
            }
          >
            <button className="replenishment_month-title -button clear-button">
              {/* See variants below */}
              <LockOkIcon className="replenishment_month-icon -lock-ok" />
              {label}
              <ChevronDown className="replenishment_month-icon -chevron" />
            </button>
          </BasePopover>

          <div className="replenishment_month-status-wrapper">
            <div className="replenishment_month-status -current" style={{ width: current }} />
          </div>
        </div>
      </ReplenishmentCut>
    )
  }

  return (
    <div className="replenishment_month">
      <div className="replenishment_month-title">
        {/*<CrossIcon className="replenishment_month-icon -cross" />*/}
        {/*<LockOkIcon className="replenishment_month-icon -lock-ok" />*/}
        <LockIcon className="replenishment_month-icon -lock" />
        {label}
      </div>
      <div className="replenishment_month-status-wrapper">
        {done && <div className="replenishment_month-status -done" />}
      </div>
    </div>
  )
}

export default MonthTitle
