import * as React from 'react'
import AuthLayout from '../../../../src/auth/AuthLayout'
import { Link } from 'react-router-dom'

const SentPage: React.FunctionComponent = () => (
  <AuthLayout>
    <form>
      <h2 className="form_title">Hands up!</h2>
      <p className="form_text">Restore your email and we will send you the new one.</p>

      <Link className="button primary-link" to="/auth/login">
        Back to SignIn
      </Link>
    </form>
  </AuthLayout>
)

export default SentPage
