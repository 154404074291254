import * as React from 'react'
import Dropdown from '../../../../components/Dropdown'
import cn from 'classnames'
import ChevronDown from '../../../../icons/chevron_down.svg'

const Breadcrumb: React.FunctionComponent = () => {
  const [client, setClient] = React.useState()
  const [branch, setBranch] = React.useState()

  return (
    <nav className="breadcrumb">
      <Dropdown
        value={client}
        itemToString={(item) => (item ? item.label : 'All clients')}
        onChange={setClient}
        options={[
          {
            label: 'ABC Inc',
            value: 1,
          },
          {
            label: 'Umbrella Corp',
            value: 2,
          },
          {
            label: 'Eugene Hello',
            value: 3,
          },
          {
            label: 'Alex and Co',
            value: 4,
          },
          {
            label: 'Castle Pest Inc',
            value: 5,
          },
        ]}
      >
        {({ setOpen, isOpen, itemToString, value }) => (
          <div className="breadcrumb_item">
            <button className="clear-button breadcrumb_button" onClick={setOpen}>
              {itemToString(value)}
              <span
                className={cn('breadcrumb_chevron', {
                  '-bold': isOpen,
                })}
              >
                <ChevronDown />
              </span>
            </button>
          </div>
        )}
      </Dropdown>
      <Dropdown
        value={branch}
        itemToString={(item) => (item ? item.label : 'All branches')}
        onChange={setBranch}
        options={[
          {
            label: 'Some Branch',
            value: 0,
          },
        ]}
      >
        {({ setOpen, isOpen, itemToString, value }) => (
          <div className="breadcrumb_item">
            <button className="clear-button breadcrumb_button" onClick={setOpen}>
              {itemToString(value)}
              <span
                className={cn('breadcrumb_chevron', {
                  '-bold': isOpen,
                })}
              >
                <ChevronDown />
              </span>
            </button>
          </div>
        )}
      </Dropdown>
    </nav>
  )
}

export default Breadcrumb
