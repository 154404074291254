import * as React from 'react'
import AuthLayout from '../../../../src/auth/AuthLayout'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { Link } from 'react-router-dom'

const RestorePage: React.FunctionComponent = () => (
  <AuthLayout>
    <form action="/markup/auth/sent">
      <h2 className="form_title">Restore password</h2>
      <p className="form_text">Restore your email and we will send you the new one.</p>

      <div className="form_group">
        <Input label="email" />
      </div>

      <div className="form_buttons">
        <Button className="form_control -size" caliber="sm" type="submit">
          Send
        </Button>
        <Link className="button -sm primary-link" to="/auth/login">
          Cancel
        </Link>
      </div>
    </form>
  </AuthLayout>
)

export default RestorePage
