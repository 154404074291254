import * as React from 'react'
import cn from 'classnames'
import ClickOutside from 'react-click-outside'
import EditIcon from '../../icons/edit.svg'
import BasePopover from '../BasePopover'
import Button from '../Button'
import Input from '../Input'
import PlaceholderIcon from './placeholder.svg'

interface IOrderSnippet {
  repack?: string
  image?: string
}

interface IProps {
  action?: 'delete' | 'add'
  type?: 'small' | 'hero'
  snippet?: IOrderSnippet
  onEdit?(state: any): void
}

const OrderSnippet: React.FunctionComponent<IProps> = ({ type, action, onEdit, snippet = {} }) => {
  const [editPopup, toggleShowEditPopup] = React.useState(false)
  const [repack, toggleRepack] = React.useState()

  return (
    <div className={cn('order-snippet', `-${type}`)}>
      <div className="order-snippet_col -info">
        <div className="order-snippet_image-wrapper">
          {snippet.image ? (
            <img className="order-snippet_image" src={snippet.image} alt="" />
          ) : (
            <PlaceholderIcon className="order-snippet_image" />
          )}
        </div>
        <div className="order-snippet_title">Some title about this snippet, which we should show here</div>
        {type === 'hero' && <div className="order-snippet_count">8 pcs</div>}
        <div className="order-snippet_sku">BAY28223</div>
        <div className="order-snippet_price">
          <b>$52</b> / 40 lbs
        </div>
      </div>
      {onEdit && (
        <div className="order-snippet_col -edit">
          {snippet.repack ? (
            <div className="order-snippet_edit-title">Repack to 3 lbs</div>
          ) : (
            <div className="order-snippet_edit-title -not-available">No repacking available</div>
          )}

          <BasePopover
            isOpen={editPopup}
            place="below"
            className="order-snippet_edit-popup"
            body={
              <ClickOutside onClickOutside={() => toggleShowEditPopup(false)}>
                <div className="order-snippet_edit-toggle">
                  {/*<Toggle*/}
                  {/*  value={repack}*/}
                  {/*  options={[*/}
                  {/*    {*/}
                  {/*      label: 'Allow repacking',*/}
                  {/*      value: 1,*/}
                  {/*    },*/}
                  {/*  ]}*/}
                  {/*  onChange={toggleRepack}*/}
                  {/*/>*/}
                </div>

                <div className="order-snippet_edit-row">
                  <div className="order-snippet_edit-col">
                    <Input caliber="sm" label="Repack to" />
                  </div>
                  <div className="order-snippet_edit-col">
                    <Input caliber="sm" label="Units of" />
                  </div>
                </div>

                <Button
                  type="button"
                  block
                  color="success"
                  onClick={() => {
                    onEdit({
                      repack,
                    })
                    toggleShowEditPopup(false)
                  }}
                >
                  Save
                </Button>
              </ClickOutside>
            }
          >
            <div className="order-snippet_edit">
              <button
                type="button"
                onClick={() => toggleShowEditPopup(true)}
                className="order-snippet_edit-opener clear-button link"
              >
                <EditIcon />
              </button>
            </div>
          </BasePopover>
        </div>
      )}
    </div>
  )
}

export default OrderSnippet
