import * as React from 'react'
import Search from '../../../components/Search'
import OrderSnippet from '../../../components/OrderSnippet'
import Header from '../../views/Header'
import Button from '../../../components/Button'
import SyncIcon from '../../../icons/sync.svg'

const CatalogPage: React.FunctionComponent = () => (
  <>
    <Header breadcrumb={[]} clientMenu />
    <div className="container">
      <div className="filter">
        <div className="filter_col -fix300">
          <Search placeholder="Search product" />
        </div>
        <div className="filter_col catalog_update-col">
          <div className="catalog_update-count">
            456 products, synced 6 days ago
          </div>
          <Button color="primary-link" className="catalog_update">
            <SyncIcon />
            Sync products</Button>
        </div>
      </div>
      <div className="catalog">
        {[
          {
            snippet: {
              repack: '3 lbs',
            },
          },
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
        ].map((item, idx) => (
          <OrderSnippet key={idx} {...item} onEdit={(state: any) => console.log(state)} />
        ))}
      </div>
    </div>
  </>
)

export default CatalogPage
