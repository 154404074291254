import * as React from 'react'
import AuthLayout from '../../../../src/auth/AuthLayout'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { Link } from 'react-router-dom'
import Alert from '../../../components/Alert'

const LoginPage: React.FunctionComponent = () => (
  <AuthLayout>
    <form>
      <Alert>That email / password combination is not valid</Alert>
      <div className="form_group">
        <Input label="email" />
      </div>
      <div className="form_group">
        <Input label="password" />
      </div>

      <div className="form_buttons">
        <Button className="form_control -size" disabled caliber="sm">
          Sign in
        </Button>
        <Link className="button -sm primary-link" to="/auth/restore">
          Forgot password
        </Link>
      </div>
    </form>
  </AuthLayout>
)

export default LoginPage
