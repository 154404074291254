import { createSelector } from 'reselect'
import { get, map } from 'lodash/fp'
import { IHash, Selector } from '../types/common'
import { IUser } from '../types/user'
import { IOption } from '../components/Dropdown'

const cMap = (map as any).convert({ cap: false })

export const tokenSelector: Selector<string | undefined> = get('auth.token')
export const currentUserSelector: Selector<IUser> = get('auth.user')
export const passwordResetUrlSelector: Selector<string> = get('auth.meta.sana.passwordResetPage')
export const appVersionSelector: Selector<string> = get('auth.meta.appVersion')

export const unitsOptionsSelector: Selector<Array<IOption<string>>> = createSelector(
  get('auth.meta.units'),
  cMap(({ text, type }: any, key: string) => ({ label: text, value: key }))
)
