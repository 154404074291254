import * as React from 'react'
import { Route, BrowserRouter } from 'react-router-dom'
import history from '../history'
import ClientsPage from './pages/ClientsPage'
import ClientPage from './pages/ClientPage'
import OrdersPage from './pages/OrdersPage'
import BranchPage from './pages/BranchPage'
import CatalogPage from './pages/CatalogPage'
import UsersPage from './pages/UsersPage'
import DivisionsPage from './pages/DivisionsPage'
import LoginPage from './pages/Auth/login'
import RestorePage from './pages/Auth/restore'
import SentPage from "./pages/Auth/sent";

const MarkupRoot: React.FunctionComponent = () => {
  return (
    <main>
      <BrowserRouter basename="/markup" >
        <Route path="/" exact component={OrdersPage} />
        <Route path="/clients" exact component={ClientsPage} />
        <Route path="/client" component={ClientPage} />
        <Route path="/catalog" component={CatalogPage} />
        <Route path="/orders" component={OrdersPage} />
        <Route path="/branch" component={BranchPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/divisions" component={DivisionsPage} />
        <Route path="/auth/login" component={LoginPage} />
        <Route path="/auth/restore" component={RestorePage} />
        <Route path="/auth/sent" component={SentPage} />
      </BrowserRouter>
    </main>
  )
}

export default MarkupRoot
