import * as React from 'react'
import Button from '../Button'

interface IProps {
  min?: number
  max?: number
  step?: number
  value?: number
  suffix?: string
  onChange(value: number): void
}

const RangeControl: React.FunctionComponent<IProps> = ({
  min = 0,
  max = 100,
  value = 50,
  step = 1,
  onChange,
  suffix,
}) => (
  <div className="range">
    <input
      className="range_control"
      type="range"
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={(e: any) => onChange(parseInt(e.target.value, 10))}
    />

    <div className="range_controls">
      <Button
        disabled={value <= min}
        className="range_button"
        caliber="xs"
        onClick={() => onChange(Math.max(value - step, min))}
      >
        Less
      </Button>
      <div className="range_value">
        {value}
        {suffix}
      </div>
      <Button
        disabled={value >= max}
        className="range_button"
        caliber="xs"
        onClick={() => onChange(Math.min(value + step, max))}
      >
        More
      </Button>
    </div>
  </div>
)

export default RangeControl
