import * as React from 'react'
import RefreshIcon from './refresh.svg'
import FillInIcon from './fillin.svg'

export type Types = 'replenishment' | 'fill_in'
export interface IProps {
  orderType: Types
}

const getIcon = (orderType: string): React.ReactNode => {
  switch (orderType) {
    case 'replenishment':
      return <RefreshIcon width={16} height={12} />
    case 'fill_in':
      return <FillInIcon width={19} height={17} />
  }
}
const OrderIcon: React.FunctionComponent<IProps> = ({ orderType }) => (
  <div className="order-icon">{getIcon(orderType)}</div>
)

export default OrderIcon
