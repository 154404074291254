import * as React from 'react'
import Search from '../../../../components/Search'
import Button from '../../../../components/Button'

const Filter: React.FunctionComponent = () => (
  <form className="filter">
    <div className="filter_col -large">
      <Search placeholder="Search Branch" />
    </div>
    <div className="filter_sep" />
    <div className="filter_col">
      <Button block>Create Branch</Button>
    </div>
  </form>
)

export default Filter
