import * as React from 'react'
import Modal from '../../../../components/Modal'
import Button from '../../../../components/Button'
import Toggle from '../../../../components/Toggle'
import Input from '../../../../components/Input'
import Select from '../../../../components/Select'
import UploadAvatar from './UploadAvatar'

interface IProps {
  onClose(): void
}

const UserDetails: React.FunctionComponent<IProps> = ({ onClose }) => {
  const [toggle, setToggle] = React.useState({
    label: 'Manager',
    value: 0,
  })

  return (
    <Modal onClose={onClose} title="Client Info">
      <form className="form">
        <div className="form_group">
          {/*<Toggle*/}
          {/*  value={toggle}*/}
          {/*  onChange={setToggle}*/}
          {/*  options={[*/}
          {/*    {*/}
          {/*      label: 'Manager',*/}
          {/*      value: 0,*/}
          {/*    },*/}
          {/*    {*/}
          {/*      label: 'Technician',*/}
          {/*      value: 0,*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*/>*/}
        </div>
        <UploadAvatar />
        <div className="form_group">
          <Input label="Name" placeholder="User name" error="some error" />
        </div>
        <div className="form_group">
          <Input label="Email" placeholder="email@forshaw.com" type="email" />
        </div>
        <div className="form_group">
          {/*<Select*/}
          {/*  label="Branch"*/}
          {/*  isMulti*/}
          {/*  options={[*/}
          {/*    { value: 'chocolate', label: 'First Division' },*/}
          {/*    { value: 'strawberry', label: 'Secondary Division' },*/}
          {/*    { value: 'vanilla1', label: 'Devision 46' },*/}
          {/*    { value: 'vanilla2', label: 'Devision 46' },*/}
          {/*    { value: 'vanilla3', label: 'Devision 46' },*/}
          {/*  ]}*/}
          {/*/>*/}
        </div>
        <div className="form_group">
          <Select
            label="Divisions"
            isMulti
            options={[
              { value: 'chocolate', label: 'First Division' },
              { value: 'strawberry', label: 'Secondary Division' },
              { value: 'vanilla1', label: 'Devision 46' },
              { value: 'vanilla2', label: 'Devision 46' },
              { value: 'vanilla3', label: 'Devision 46' },
            ]}
          />
        </div>
        <div className="form_controls">
          <div className="form_controls-container">
            <Button color="red-link" className="form_control -left">
              Delete user
            </Button>
            <Button color="default-link" className="form_control -size">
              Cancel
            </Button>
            <Button color="success" className="form_control -size">
              Save
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default UserDetails
