import * as React from 'react'
import ResetIcon from '../../../icons/reset.svg'
import BasePopover from '../../../components/BasePopover'
import OrderIcon from '../../../components/OrderIcon'

interface IProps {
  isOpen?: boolean
  text?: string
  list?: []
  onShow(): void
  onHide(): void
}

const ReplenishmentCut: React.FunctionComponent<IProps> = ({ isOpen, children, onShow, onHide, text, list }) => {
  return (
    <BasePopover
      preferPlace="below"
      isOpen={isOpen}
      className="replcut"
      body={
        <div className="replcut_body" onMouseLeave={onHide} onMouseEnter={onShow}>
          {text && <div className="replcut_text">{text}</div>}

          {list && (
            <>
              <div className="replcut_year">
                2019 <div className="replcut_circle -pink" />
              </div>
              <div className="replcut_title">15 forecasted</div>
              <div className="replcut_sep" />
              <div className="replcut_title">12 planned</div>
              <div className="replcut_sep" />
              <ul className="replcut_list">
                <li>
                  <b><span className="replcut_mark">14</span> → 12</b>, 6 hours ago by John Snow
                </li>
              </ul>

              <button className="replcut_reset clear-button">
                <ResetIcon /> Reset to estimated
              </button>

              <div className="replcut_sep" />
              <div className="replcut_title">27 actual:</div>

              <ul className="replcut_list">
                <li>
                  <dl>
                    <dt>
                      <div className="replcut_list-icon">
                        <OrderIcon orderType="replenishment" />
                      </div>
                      June 5
                    </dt>
                    <dd>5</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>
                      <div className="replcut_list-icon">
                        <OrderIcon orderType="fill_in" />
                      </div>
                      June 15
                    </dt>
                    <dd>10</dd>
                  </dl>
                </li>
              </ul>
            </>
          )}
        </div>
      }
    >
      {children}
    </BasePopover>
  )
}

export default ReplenishmentCut
