import * as React from 'react'
import Filter from './Filter'
import Header from '../../views/Header'

const clients: Array<{
  title: string
  manager: string
  technicians: number
  products: number
}> = [
  {
    title: 'Main Division',
    manager: 'Joe Wilson',
    technicians: 8,
    products: 24,
  },
  {
    title: 'Secondary Division',
    manager: 'Joe Wilson',
    technicians: 12,
    products: 36,
  },
]

const DivisionsPage: React.FunctionComponent = () => {
  return (
    <>
      <Header breadcrumb={[]} clientMenu />
      <div className="container">
        <Filter />
        <div className="table">
          <div className="table_head-tr">
            <div className="table_td -g25 table_title">Division</div>
            <div className="table_td -g25 table_title">Warehouse manager</div>
            <div className="table_td -g25 table_title">Technicians</div>
            <div className="table_td -g25 table_title">Products</div>
          </div>
          {clients.map((client, idx) => (
            <div className="table_tr" key={idx}>
              <td className="table_td -g25">{client.title}</td>
              <td className="table_td -g25">{client.manager}</td>
              <td className="table_td -g25">{client.technicians}</td>
              <td className="table_td -g25">{client.products}</td>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default DivisionsPage
