import * as React from 'react'
import Search from '../../../../components/Search'
import Button from '../../../../components/Button'
import ImportClients from "../../../views/ImportClients";

const Filter: React.FunctionComponent = () => {
  const [importClients, toggleImportClients] = React.useState(false)

  return (
    <form className="filter">
      <div className="filter_col -large">
        <Search placeholder="Search Client" />
      </div>
      <div className="filter_sep" />
      <div className="filter_col">
        <Button block onClick={() => toggleImportClients(true)}>New Client</Button>
      </div>
      {importClients && <ImportClients onClose={() => toggleImportClients(false)}/>}
    </form>
  )
}

export default Filter
